const ErrorList = [
  {
    error: -10021,
    reason: '无效的请求参数',
  },
  {
    error: -10022,
    reason: '未找到应用信息',
  },
  {
    error: -10023,
    reason: '找到应用信息, 但应用暂时不可用',
  },
  {
    error: -10024,
    reason: '不支持的授权方式',
  },
  {
    error: -10025,
    reason: '应用不支持该授权方式',
  },
  {
    error: -10027,
    reason: '无效的授权范围',
  },
  {
    error: -10028,
    reason: '授权服务内部错误',
  },
  {
    error: -10029,
    reason: '授权回调页地址错误',
  },
];


export function getError(error: number) {

  return ErrorList.find(i => i.error === error);
}
