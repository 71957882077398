import { TRouterType } from '@common/types/route';
import { getObjectValueOfKey, freeze } from '@shared/utils/util';

const config = (window as any).__CONFIG__ || {
  apiUrl: 'http://api.assure.com/api',
};

/**
 * 页面配置
 */
export interface IPageConfig {
  core: {
    router?: TRouterType;
  },
  apiUrl: 'http://api.assure.com/api',

  [index: string]: any;
}

const __config = window.__config;

// freeze(__config);
// freeze(config);

/**
 * 获取配置
 * @param key
 * @param defaultValue
 */
export function getConfig<T = any>(key?: string, defaultValue?: T) {
  if (!key) {
    return __config;
  }
  const v = getObjectValueOfKey<T>(key, __config);
  return v === null ? (typeof defaultValue === 'undefined' ? null : defaultValue) : v;
}

const finalConfig = {
  ...config,
  ID_KEY: '__WX_ID__',
  TOKEN_KEY: '__VX_ID__',
  META_KEY: '__VX_META__',
  apiUrl: config.apiUrl,
  baseUrl: config.baseUrl,
  devUrl: config.devApi,
  cdnUrl: config.cdnUrl,
  editorAweSomeUrl: config.editorAweSomeUrl,
  signKey: 'wenwen',
  access: {
    ...config.access,
  },
};
export default finalConfig;
