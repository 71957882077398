/**
 * 依据属性排序
 * @param prop
 */
import { MapType } from '@common/types/interface';

export function sortByProp(prop: string) {
  return function(obj1: MapType<any>, obj2: MapType<any>) {
    const val1 = obj1[prop];
    const val2 = obj2[prop];
    if (val1 < val2) {
      return -1;
    } else if (val1 > val2) {
      return 1;
    } else {
      return 0;
    }
  };
}

export function sortObjectProp(obj: MapType<any>, flag = true) {
  const keys = Object.keys(obj).sort((a: any, b: any) => Number(flag ? a > b : b > a));
  const result: any = [];
  keys.forEach(item => {
    result.push([item, obj[item],
    ]);
  });
  return result;
}


export function getCurrentMonthFirst() {
  const date = new Date();
  date.setDate(1);
  return date;
}

// 获取当前月的最后一天
export function getCurrentMonthLast() {
  const date = new Date();
  let currentMonth = date.getMonth();
  const nextMonth = ++currentMonth;
  const nextMonthFirstDay: any = new Date(date.getFullYear(), nextMonth, 1);
  const oneDay = 1000 * 60 * 60 * 24;
  return new Date(nextMonthFirstDay - oneDay);
}


export function isEmptyObject(obj: any) {
  return JSON.stringify(obj) === '{}';
}

/**
 * 获取对象数据
 * @param key
 * @return {*}
 */
export function getObjectValueOfKey<T = any>(key: string, config: MapType<any>) {
  let temp: any = Object.assign({}, config);
  if (!key) {
    return temp;
  }
  const keyPath = key.split('.');
  let result: T = null as any;
  do {
    const keyItem = keyPath.shift();
    if (keyItem) {
      if (typeof temp === 'object') {
        if (temp === null) {
          return null;
        }
        result = temp.hasOwnProperty(keyItem) ? temp[keyItem] : null;
        temp = result;
      }
    }
  } while (keyPath.length);
  return result as any as T;
}


/**
 * freeze object
 * @param obj
 */
export function freeze(obj: any) {
  Object.freeze(obj);
  Object.values(obj).forEach((item: any) => {
    if (typeof item === 'object') {
      freeze(item);
    }
  });
}
