import bootstrap from '@pages/index/bootstrap';

window.__authorize_callback = (data: any) => {
  window.__config.__result = data;
};

function inject() {
  const script = document.createElement('script');
  script.src = window.__config.openapiUrl + '/oauth/2.0/authorization' + location.search;
  document.head.append(script);
  script.onload = () => {
    setTimeout(() => {
      bootstrap();
    }, 20);
  };
}

inject();

