import * as React from 'react';
import { Suspense, useEffect } from 'react';
import './style';
import { getConfig } from '@common/config';
import { getError } from '@pages/index/error';
import { Spin } from 'antd';

const LoginPage = React.lazy(() => import('@pages/index/routes/login/LoginPage'));
const Layout: React.FC<{ home: string; registerLink: string; }> = ({ home, registerLink, children }) => {
  return (
    <div className='app-ouath-login'>
      <div className='login-page'>
        <div className='header'>
          <div className='left'>
            <div className='page-logo' title='微风平台'>
              <img alt='微风平台' src='/logo.png' onClick={() => {
                window.open(home, '_blank');
              }} />
            </div>
          </div>
          <div className='right'>
            <a href={registerLink} target='_blank'>注册</a>
          </div>
        </div>
        <div className='body'>
          {children}
        </div>
      </div>
    </div>
  );
};
const App = () => {
  const { registerLink, home } = getConfig('__app_data', {});
  const { url, authorized, error, reason, access, app } = getConfig('__result', {});
  useEffect(() => {
    if (authorized) {
      window.location.replace(url);
    }
  }, []);
  if (error) {
    const o = getError(error);
    if (o) {
      return (
        <Layout home={home} registerLink={registerLink}>
          <div className='sys-error'>
            <h2 className='sys-error-title'>授权失败了</h2>
            <div className='sys-error-content'>
              <div>
                <span>错误描述：</span>
                <span>{o.reason}</span>
              </div>
              <div>
                <span>错误代码：</span>
                <span style={{ color: '#f00' }}>{o.error}</span>
              </div>
              <div className='sys-error-home'>
                <a href={home}>返回首页</a>
              </div>
            </div>
          </div>
        </Layout>
      );
    }
    return (<div>
      Error:{reason}
    </div>);
  }
  if (authorized) {
    return (
      <h2>跳转中...</h2>
    );
  }
  const { face, name: appName, appid: clientId } = app;
  return (
    <Layout home={home} registerLink={registerLink}>
      <Suspense fallback={
        <Spin style={{ height: 200 }} spinning tip='环境验证....'>
          <div />
        </Spin>
      }>
        <LoginPage
          clientId={clientId}
          home={home}
          appName={appName}
          registerLink={registerLink}
          face={face}
          access={access}
        />
      </Suspense>
    </Layout>
  );
};
export default App;
